*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.signup-inputs{
  font-size: 15px;
}
.signup-inputs::placeholder{
font-size: 15px;
}

.main-div{
    background-image: url('../src/images/Group\ 1171276003.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar{
border-bottom: 2px solid #0D6EFD;
color: #0D6EFD;
font-weight: 500;
}


.sub-page{
    background-image: url('../src/images/66331-OBLGZ7-442\ 1.png');
    background-position: center;
    background-repeat: repeat-y;
    background-size: cover;
}

.back-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #185CFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .back-to-top-btn.show {
    opacity: 1;
  }
  
  .back-to-top-btn.hide {
    opacity: 0;
  }
  
.success{
    /* background-image: url('../src/images/Group\ 1171275995.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.services-div{
    background-image: url('../src/images/img_11.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 340px;
}

.sub-div{
    background-image: url('../src/images/Group\ 1171276000.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
}

.subcategory-subdiv{
  background-image: url('../src/images/subCategories/Group\ 1171276011.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}

/* .sub-div:hover{
  transform: translateY(-6%);
} */

.sublang-div{
  background-image: url('../src/images/subCategories/Mask\ group\ \(1\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.subpage-subdiv{
  background-image: url('../src/images/subCategories/container.png');
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-list{
    font-size: 13px;
}

.selected-questions{
    background-color: #50AD2A;
    border: 1px solid #50AD2A;
    color: white;
}
.questions{
    background-color: white;
    border: 1px solid #B5B9BB;
    color: gray;
}
.marked-questions{
  background-color: #FDDE4C;
  border: 1px solid #FDDE4C;
  color: white;
}

.selected-options{
    background-color: #185CFF;
    /* border: 1px solid #185CFF; */
    color: white;
}
.options{
    background-color: white;
    /* border: 1px solid #B5B9BB; */
}

.cat_text{
  background: linear-gradient(186deg, rgba(24,200,255,1) 0%, rgba(147,63,254,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-categories-div{
  background-image: url('../src/images/subCategories/Background.png');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}

.instruction-para{
  background-color: rgb(194, 221, 250,0.6);
  min-height: 60px;
  padding: 10px;
  border-radius: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 13px;
}

@media screen and (max-width: 767px){
  .cat_text{
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px){

}

@media screen and (min-width: 1024px){
    .login{
        width: 70%;
    }
    .project-main-div{
        /* margin-top: 77px; */
    }

    .main-container{
        margin-top: 78px;
        margin-left: 230px;
    /* margin-top: 70px; */
    min-height: 100vh;
    background-color: #F2F2F2;
    }
    .nav-container{
        margin-left: 220px;
    }
    .cat_text{
      font-size: 50px;
      line-height: 60px;
    }
    .home-form{
      width: 445px;
    }

    .sub-div{
        height: 220px;
        width: 480px;
    }
    .subcategory-subdiv{
      height: 170px;
      /* width: 450px; */
    }
    
    .sub-div2{
        display: flex;
        justify-content: end;
    }
}

/* Admin panel */
.sidebar{
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 6;
    transition: 0.4s;
}


  .main-container2{
    min-height: 100vh;
    margin-top: 60px;
  }

  .table_tr_color{
    background-color: #F7F6FE;
  }

  .sidebar-color{
  background-color: #F2F2F2;
  border-radius: 30px 0px 0px 30px;
  height: 53px;
  }
  .sidebar-li{
    height: 60px;
    }
  .sidebar-text{
    color: #BFBFBF;
  }
  .navbar-item{
    position: relative;
  }
  .nav-subtext{
    z-index: 10;
  }

  .add-option{
    font-size: 14px;
  }

  .test-page{
    background-image: url('../src/images/Group\ 1171276021.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .options-div{
    background-image: url('../src/images/Card\ 11.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .question-div{
    background-image: url('../src/images/Card\ 12.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 20%;
  }
  .all-questions{
    background-image: url('../src/images/Rectangle\ 115.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .add-option::placeholder{
    font-size: 14px;
  }

  .create-question-div{
    background-image: url('../src/images/admin/Image\ Placeholder\ \(1\).png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;
  }

  .btns{
    border-radius: 15px 15px 15px 0px;
  }

  .inputs{
    font-size: 14px;
  }
  .inputs::placeholder{
    font-size: 14px;
  }

  /* Projects */

.project-main-div{
    background-image: url('../src/images/Group\ 48095863.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }
    .project-nav{
      /* background-image: url('../src/images/project/Group\ 48095844.png'); */
      background-position: center;
      background-repeat: no-repeat;
      /* background-size: cover; */
      }
    
      .vertical-text {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        display: inline-block;
        font-size: 24px;
    }
    
    .sub-div3{
    background-image: url('../src/images/Group\ 48095840\ \(1\).png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 168px;
    border-radius: 10px;
    }
    
    .projects{
      border-top: 6px solid #1F87FF;
      border-bottom: 1px solid gray;
      border-left: 1px solid gray;
      border-right: 1px solid rgb(104, 103, 103);
    }
    
    .projects2{
      border-top: 6px solid #1F87FF;
    }
    
    .sub-div4{
      background-image: url('../src/images/Group\ 48095840\ \(1\).png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      }
    
    .project-dash{
     /* background-image: url('../src/images/Group\ 48095804.png'); */
     background-position: center;
     background-repeat: repeat-y;
     /* background-size: cover; */
    }
    
    .project-view{
      background: rgb(63,39,102);
      background: linear-gradient(90deg, rgba(63,39,102,1) 0%, rgba(54,53,103,1) 100%);
    }
    
    .class1{
      background-color: #57309C;
    }
    .class2{
      color: #57309C;
    }
    .border2{
      border: 1px solid #57309C;
    }
    
    .view-btns{
      cursor: pointer;
      background: rgb(49,48,54);
    background: linear-gradient(360deg, rgba(49,48,54,1) 30%, rgba(121,117,131,1) 100%, rgba(255,255,255,1) 100%);
    }
    
    .view-selected-btn{
      cursor: pointer;
      background: rgb(24,200,255);
    background: linear-gradient(230deg, rgba(24,200,255,1) 0%, rgba(147,63,254,1) 100%);
    }
    
    .view-project{
      /* background-image: url('../src/images/card2.png'); */
      background-position: center;
      background-repeat: repeat;
      background-size: cover;
    }
    
    .nav-btn{
      background: rgb(24,200,255);
    background: linear-gradient(200deg, rgba(24,200,255,1) 0%, rgba(147,63,254,1) 100%);
    }

    .offcanvas1{
      transition: 0.8s ease;
    }

  /* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the vertical scrollbar */
  height: 8px; /* Adjust the height of the horizontal scrollbar */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: lightgrey;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px; /* Adjust the border-radius to match the reduced width */
  cursor: pointer;
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

.signup-labels{
  font-size: 14px;
}

.filter-input::placeholder{
  color: #9e9e9e;
}
.filter-label{
  color: #9E9E9E;
}
.btns{
  color: #4787E3;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}